html,
body,
#root {
  background-color: #121212;
  color: #fbfbfb;
  height: 100%;
  overflow-x: hidden;
}

a, a:visited {
  text-decoration: none;
  color: #f2a900
}

a:hover, a:active {
  text-decoration: none;
  color: #ff7760
}

ul {
  padding: 0 48px;
  text-align: center;
  list-style: none;
  display: block;
}

ul li {
  padding: 10px;
  display: block;
}

body {
  margin: 0;
  font-size: 18px;
  font-family: monospace;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 0.65em;
}

code {
  font-family: monospace;
}

a h5 {
  color: #fbfbfb;
  font-size: 1.4em;
  margin: 1em;
}
