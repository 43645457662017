#bitcoin {
  margin: 40px auto 0;
  width: 80%;
  display: block;
  text-align: center;
  color: #888888;
}
#bitcoin #bitcoin_svg {
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #ee9209;
  width: 100px;
  height: 100px;
  padding-top: 12px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  opacity: 0;
  animation: fade 1.2s linear 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
#bitcoin #bitcoin_svg:hover {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  -webkit-transform: scale(1.1) rotate(720deg);
  -moz-transform: scale(1.1) rotate(720deg);
  -ms-transform: scale(1.1) rotate(720deg);
  -o-transform: scale(1.1) rotate(720deg);
  transform: scale(1.1) rotate(720deg);
}
#bitcoin #bitcoin_svg svg .path {
  stroke-width: 2;
  stroke-linecap: round;
  fill-opacity: 0;
  fill: #fff;
  stroke: #fff;
  animation: dash 1.2s linear, fill 0.5s linear 1s;
  -webkit-animation-fill-mode: forwards;
  /* Chrome, Safari, Opera */
  animation-fill-mode: forwards;
}
#bitcoin #bitcoin_message {
  opacity: 0;
  width: 100%;
  display: block;
  font-size: 1.3rem;
  text-align: center;
  padding: 15px;
  animation: fade 1.2s linear 1.5s;
  -webkit-animation-fill-mode: forwards;
  /* Chrome, Safari, Opera */
  animation-fill-mode: forwards;
}
#bitcoin #bitcoin_qr img {
  opacity: 0;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  animation: fade 1.2s linear 1.5s;
  -webkit-animation-fill-mode: forwards;
  /* Chrome, Safari, Opera */
  animation-fill-mode: forwards;
}
#bitcoin #bitcoin_qr img:hover {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 2110;
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@keyframes fill {
  0% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
